/* 
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight:700
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
} */
/* :root {
  --background-color-light: #F0F0F5;
  --text-color-light: #282c34;

  --background-color-dark: #282c34;
  --text-color-dark: #F0F0F5;
} */

/* 
blue #788a92;
*/

:root {
  --background-color-light: #f0f0f5;
  --text-color-light: #2b2b2c;

  --background-color-dark: #2b2b2c;
  --text-color-dark: #f0f0f5;

  --text-color-blue: #788a92;
}

/* 

background: #F0F0F5;
  color: #282c34;
*/

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/font/RobotoMono-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/font/RobotoMono-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/font/RobotoMono-Regular.ttf");
  font-weight: 400;
}

/* @media screen and (width: 1000px), screen and (width: 700px) {
  div {
      padding-right: 30px; 
  }
} */

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1250px) {
  .buttons-n-carousel-together {
    flex-wrap: wrap;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    /* background: magenta; */
  }

  .line,
  .line1 {
    padding-left: 0px !important;
  }

  .blog-page-image-grid {
    grid-template-columns: auto auto auto auto !important;
  }
}
@media screen and (max-width: 500px) {
  .giant-carousel {
    /* border-bottom: 5px solid gray; */
    height: 280px !important;
    margin-top: -15px;
    margin-bottom: 15px;
  }
  .giant-carousel {
    width: 400px !important;
    font-size: 10.5px !important;
    line-height: 3;
  }
  .carousel-lines {
    margin-left: 14% !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(2%);
    bottom: calc(0%);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(2%);
    bottom: calc(0%);
  }
  .react-multi-carousel-dot-list li button {
    width: 9px;
    height: 9px;
  }
}
@media (max-width: 700px) {
  .set3-buttons {
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
  }
  .poetry-button {
    font-size: 11px;
    width: 75px !important;
    height: 30px !important;
  }
  .archive-section-title-container-title {
    margin-left: 10% !important;
  }
  .archive-section-title-container-subtext {
    margin-left: 12% !important;
  }

  .nav-bar {
    font-size: 12px;
  }

  .nav-bar > * {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  #tog {
    position: absolute !important;
    right: 0;
    padding: 0 !important;
  }
}

/* blog-page */
@media (max-width: 600px) {
  .something-big {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-left: 0% !important;
  }
  .latest-posts-blog-links {
    padding-left: 5% !important;
    padding-right: 100px;
  }

  .word-list-container,
  .archive-list-container,
  .archive-list-container❤️,
  .diary-label,
  .haiku {
    margin-left: 5% !important;
    margin-right: 0 !important;
  }

  .blog-page,
  .archive-giant-container,
  .home-n-blog-page {
    font-size: 11.5px !important;
    font-family: "Roboto Mono";
    /* color: red; */
  }
  .blog-page-my-words p ul li {
    flex-wrap: wrap;
    font-size: 8px;
  }
  .blog-page-my-words {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .blog-page-title,
  .blog-page-date,
  .movie-tabs {
    padding-left: 5% !important;
  }
  .bottom-side-movie-comments {
    margin-right: 5% !important;
    font-size: 10px;
  }
  .right-side-movie-quotes,
  .right-side-movie-notes {
    font-size: 10px;
  }
  .blog-page-my-words {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .blog-page-quote {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  .dropdown-toggle {
    position: relative;
    font-size: 11.5px;
    display: flex;
    align-items: center;
    color: #788a92;
    /* background: #2b2b2c; */
    padding-right: 50px;
    margin-left: 5% !important;
    width: 120px !important;
    height: 25px !important;
    border-radius: 5px;

    margin-bottom: 25px;
  }
  .button-content {
    margin-left: 3px !important;
    margin-top: 0 !important;
  }

  .blog-page-image-grid {
    border-left: 4px solid #969696;
    padding-left: 0px !important;
    margin-left: 5% !important;
    padding-right: 5% !important;
    grid-template-columns: auto auto auto !important;
    justify-content: space-between !important;
  }

  .blog-page-images-theImage❤️ {
    width: 100% !important;
  }
  .blog-page-images-theImage🐵 {
    width: 100% !important;
  }
  .spotify-music {
    width: 320px !important;
    height: 80px;
  }
}
@media (max-width: 600px) {
  .blog-page-images-theImage⭐ {
    width: 240px !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  .blog-page-images-theImage👻 {
    width: 300px !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
  .blog-page-images-ProjectDM {
    width: 350px !important;
  }

  .blog-giant-carousel1 {
    width: 300px !important;
    height: 300px !important;
    margin-top: -35px;
    /* background:blue !important; */
  }
  .blog-giant-carousel2 {
    width: 300px !important;
    height: 300px !important;
  }
  .blog-giant-carousel3 {
    width: 300px !important;
    height: 300px !important;
  }
  .nav-bar-container,
  .home-n-blog-footer,
  .archive-footer,
  .seasons button,
  .seasons h3 {
    font-size: 12px !important;
    justify-content: center !important;
  }
  .seasons-container {
    text-align: center;
  }
  .seasons {
    margin: 0 !important;
  }
}

@media (max-width: 850px) {
  .blog-page ul li time p,
  .nav-bar button {
    right: 5% !important;
  }
  .archive-list-container❤️ hr {
    margin-right: 5% !important;
  }

  .blog-paradise {
    text-align: left;
  }
  .blog-page-my-words ul li {
    font-size: 10px !important;
    text-align: left;
  }
}

@media (max-width: 1100px) {
  l .poetry-header-inside-title {
    justify-content: center !important;
    display: center !important;
    align-items: center !important;
  }
}

/*Default CSS*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: auto;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  height: 100vh;
}

#router {
  min-height: 100vh;
}

body {
  font-weight: 400;

  /* background: red; */
  /* background-color: var(--background-color-light);
  color: var(--text-color-light); */
  background: #f0f0f5;
  color: #2b2b2c;
  font-family: "Roboto Mono", sans-serif !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body.light-mode {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

/* Dark mode styles */
body.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}
body.dark-mode a {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

/* body.dark-mode h2,
body.dark-mode h3{
color: #788a92;
} */

body.dark-mode h2 {
  color: var(--background-color-light);
}

body.dark-mode h1 {
  color: var(--background-color-light);
}
/* body.dark-mode .archive-list-container❤️{
  border-left: solid #F0F0F5;;
} */

body.dark-mode .balloon {
  filter: grayscale(100%);
}

h1 {
  padding: 20px;
  color: #121212;
  line-height: normal;
  text-align: center;
}

h2 {
  color: #121212;
  line-height: normal;
}
/* h1,h2,h3,h4,h5,h6 {
  margin: 0;
  line-height: normal;
  text-align: center;
  
  
} */

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.nav-bar {
  font-family: "Roboto Mono";
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  line-height: 1;
}

.nav-bar .pages,
.nav-bar .pages-line {
  margin: 0;
  display: inline-flex; /* Makes them behave more consistently */
  align-items: center;
}

body.dark-mode .pages-line {
  color: var(--background-color-light);
}

.nav-bar span:hover {
  /* border: 2px solid #788a92; */
  cursor: pointer;
}
#tog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1;
}

/* .nav-bar button{
  position: absolute;
  right: 30%;
  margin-top: 2px;
} */

/* 
  --background-color-dark: #2b2b2c;
  --text-color-dark: #F0F0F5;
*/

body.dark-mode .nav-bar button {
  background: #f0f0f5;
}

.nav-bar .pages:hover {
  font-weight: bold;
}

.title {
  color: #121212;
  padding-bottom: 20px;
}
.line,
.line1 {
  color: #121212;
  justify-content: left;
}

.nav-bar {
  /* color: #F0F0F5; */

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  position: relative;
}

.nav-bar > * {
  padding: 20px;
  color: #282c34;
  text-decoration: none;
}

.home-n-blog-footer {
  width: 100%;
  display: flex;
  color: #121212;
  padding: 20px;
  font-size: 14px;
  justify-content: center;
  /* background: rgb(40, 174, 100); */

  margin-top: 5%;
  /* padding-bottom: 20px; */
  text-align: center;
  position: absolute;
  bottom: 0%;
}
.archive-footer {
  width: 100%;
  display: flex;
  color: #121212;
  padding: 20px;
  font-size: 14px;
  justify-content: center;
  /* background: #6cf; */
}

body.dark-mode .home-n-blog-footer,
body.dark-mode .archive-footer {
  color: var(--background-color-light);
}

/* Everything Poetry */
/* Carousel */
.Poetry-Header {
  display: block;
  padding-left: 25%;
}
.Carousel {
  display: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}
.L1,
.L2,
.L3,
.L4,
.L5,
.L6,
.L7 {
  transition: ease-in-out;
}
.carousel-line-title {
  padding-left: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  line-height: 1;
}

.texting {
  align-items: center;
  width: 1000px;
  min-width: 500px;
}
.swiper_container {
  /* border: 5x solid black; */
  display: flex;
  height: 400px;
  margin: 0 auto;
  max-width: 40px;
  position: relative;
  width: 10%;
  padding-top: 50px;
  padding-bottom: 50px;
  color: #121212;
  padding: 100px;
}

.swiper.slide.swiper-slide-active {
  width: 10px;
  max-width: 10px;
  padding: 1000px;
}
.swiper-slide {
  position: relative;
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 100px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 15px;
  color: #121212;
}

/* .slide{
  border-radius: 2%;
  filter: drop-shadow(0 8px 24px rgba(18,28,53,0.1));
  width: 450px;
  height: 350px;
  opacity: 4;
  padding-top: 25px;
  padding-bottom: 200px;
  text-align: left;
  border: rgba(18,28,53,0.1);
  box-shadow: 2px 2px 40px -5px rgba(8, 10, 16, 0.1);
  margin-right: auto;
  margin-left: auto;
  display: grid;
  overflow: hidden;
  padding-left:-400px;
} */

.testing {
  color: #121212;
  justify-content: center;
}

.line,
.line1 {
  line-height: 2;
}
.line1 {
  padding-top: 15px;
}

/* About Page */
.aboutpage {
  color: #121212;
  padding-left: 25%;
}

/* testing the new carousel component */
.test-carousel-component {
  height: 375px;

  /* background:blue; */
  border-radius: 18px;
  /* border: 3px solid #121212; */
  text-align: left;
}

.carousel-lines {
  text-align: left;
  margin-left: 24%;
  width: auto;
  line-height: 1.8;
}

.giant-carousel {
  height: 250px;
  text-align: right;
  width: 600px;
  display: center;
  align-items: center;
  font-size: 13px;
}

.Carousel {
  width: 50%;
}
/* buttons for poems / poem buttons /poembuttons*/
.set3-buttons {
  background: transparent;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.buttons-n-carousel-together {
  display: flex;
  flex-direction: row !important;
}
.react-multiple-carousel__arrow {
  filter: invert(1);
}
body.dark-mode .react-multiple-carousel__arrow {
  filter: invert(0);
}
.react-multiple-carousel__arrow--left {
  background: transparent;
}
.react-multiple-carousel__arrow--right {
  background: transparent;
}

/* .stars{
  color: green;
 } */

.great-next {
  margin-left: 10px;
  margin-bottom: 10px;
}

.movie-images {
  margin-left: 15px;
  border-radius: 6%;
  outline: 2px solid #969696;
  filter: grayscale(87%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  height: auto;
  transition: transform 0.2s;
}
.movie-images:hover {
  transform: scale(1.2);
  outline: 3px solid #0077b6 !important;
}
.right-side {
  padding-left: 15px;
}
.right-side-movie-stars {
  display: flex;
  align-items: center;
  padding-left: 2px;
  color: #788a92;
  margin-bottom: 15px;
  /* background: green; */
  width: auto;
}
.star-date {
  display: flex;
  height: 40px;
}
.star-date span {
  padding-left: 8px;
  display: flex;
  align-items: center;

  /* background: red; */
  height: 25px;
  font-size: 9px;
}

.right-side-movie-quotes {
  /* border-left: 2.5px solid #969696; */
  line-height: 2;
  /* margin-bottom:8px; */

  /* margin-top:15px; */
  /* font-weight: 550; */
}
.right-side-movie-quotes p {
  border-left: 2.5px solid #969696;
  margin-bottom: 8px;

  margin-right: 5px;
  padding-left: 15px;
  text-indent: -7px;
}
.right-side-movie-comments {
  line-height: 2;
}
.movie-title-container {
  /* border-left: 5px solid #969696; */
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;

  /* padding-left: 15px; */
  /* margin-bottom:35px; */
}
.right-side-movie-notes {
  font-weight: lighter;
}
.bottom-side-movie-comments {
  padding-left: 18px;
  line-height: 2;
}
.poetry-button {
  /* font-family: 'Roboto Mono'; */
  font-family: "Roboto Mono";

  border-radius: 3px;
  margin: 1.1em;
  /* background:
    #2B2B2C
; */
  border: 2px solid #2b2b2c;
  /* color: #76689A; */
  color: #788a92;
  width: 80px;
  height: 35px;
  opacity: 40;
  box-shadow: 0 0 1px #2b2b2c;
}
body.dark-mode .poetry-button {
  border: 2px solid #f0f0f5;
  background: #788a92;
  color: #2b2b2c;
}

body.dark-mode .poetry-button:hover {
  background: #f0f0f5;
  border: 2px solid #788a92;
  cursor: pointer;
}
.poetry-button:hover {
  background: #2b2b2c;
  cursor: pointer;
}

.set3-buttons a {
  /* text-decoration: none; */
  color: #76689a;
  display: center;
  align-items: center;
  justify-content: center;
}

.something-big {
  /* background: red; */
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  /* text-align:center;
  justify-content: center; */
  margin-left: 20%;
}
.something-big-blog-c1,
.something-big-blog-c2 {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

/* Archive / EverythingArchive Everything Archive*/
.archive-section {
  align-items: flex;
  /* background: green; */
}
.archive-giant-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
.archive-section-title-container {
  padding-bottom: 15px;
}
.archive-section-title-container-title {
  margin-top: 50px;
  margin-left: 22%;
  padding-left: 10px;
  color: #788a92;
  width: 110px;
  height: 40px;
  border-radius: 5px;
}

.archive-section-title-container-subtext {
  margin-left: 25%;
}
.archive-list-container {
  border-left: 4px solid #969696;
  margin-left: 20%;
  margin-bottom: 30px;
}
.haiku {
  margin-left: 20%;

  margin-bottom: 30px;
  border-left: 2.5px solid #969696;
  line-height: 2;
}
.haiku p {
  padding-left: 18px;
}
.archive-list-container ul li {
  list-style-position: inside;

  list-style-type: circle;
  padding: 15px;
  /* background:red; */
  line-height: 1.5;
  padding-top: 7px;
  padding-bottom: 7px;
}
.archive-list-container ul li a:active,
a:link,
a:visited,
a:hover {
  color: #282c34;
}
.archive-list-container❤️ {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 30px;
  margin-top: 30px;
  border-left: 4px solid #969696;
}
.archive-list-container❤️ hr {
  margin-right: 25%;
  color: #969696;
  border-color: #969696;
  background-color: #969696;
}
.word-list-container {
  margin-left: 20%;
  border-left: 4px solid #969696;
  margin-bottom: 30px;
  line-height: 2;
}
.word-list-container ul li {
  list-style-type: none;
  margin-bottom: 30px;
}
.archive-section-quote {
  padding-left: 2%;
}
.archive-section-author {
  padding-left: 8%;
}

.something-big-poetry-title-subtext-bullets ul {
  font-size: 11px;
  padding-left: 18px;
}
.poetry-header-inside-title {
  /* width: 200px;
  
  align-items:center;
  justify-content:center; */

  padding-top: 5%;
  padding-left: 25%;
  padding-bottom: 5px;
}
.movie-quotes {
  padding-left: 50px;
}

.paradise {
  width: 50ch;
  overflow: wrap;
}

/* Everything there is to know about blog / blog-page / posts / latestposts */
.latest-posts {
  padding: 50px;
  padding-left: 22%;
}
.latest-posts-blog-links {
  padding-left: 20%;
  margin-bottom: 45px;
  line-height: 1.8;
  /* display: flex;
  flex-direction: column; */
}

.latest-posts-blog-links li {
  display: flex; /* This is making the space between bullet and text */
  text-align: right;
}
.blog-page {
  font-size: 15px;
  font-family: "Roboto Mono";
}
.blog-page-title {
  margin-top: 35px;
  padding-left: 18%;
}
.movie-tabs {
  padding-left: 18%;
}
.blog-page-quote {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  margin-left: 20%;
  margin-right: 20%;
  border-left: 3.5px solid #969696;
  line-height: 2.5;
}

.blog-page-quote ol {
  margin-left: 15px !important;
  list-style-type: disc;
}

.blog-page-date {
  padding-left: 19%;
  margin-bottom: 25px;
}

.blog-page-my-words {
  padding-left: 22%;
  padding-right: 22%;
  line-height: 2.4;
  /* padding-top: 25px; */
  padding-bottom: 0px;
}
.blog-page-my-words p {
  padding-top: 15px;
  padding-bottom: 15px;
}
.blog-page-my-words ul {
  padding-left: 15px;
  list-style-type: circle !important;
}

.blog-page-my-words ul li {
  list-style-type: disc !important;
  display: list-item !important;
}
.blog-page-my-words ul ul {
  padding-left: 30px;
}
.blog-page-my-words ul ul li {
  list-style-type: circle !important;
  line-height: 1.5;
}

.blog-page-my-words h3{
  border-bottom: 2px solid var(--text-color-light);

}

body.dark-mode .blog-page-my-words h3{
  border-bottom: 2px solid var(--text-color-dark);
}


.conversation {
  line-height: 1;
}

.conversation-line {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: left;
}

.seasons {
  /* justify-content: center;
  text-align: center;
  align-items: center; */
  display: inline-block;
  margin-top: 2%;
  margin-left: 18%;
  margin-right: 10%;
  /* display: grid;
  grid-template-columns: auto auto auto auto; */
  border-bottom: solid 1px gray;
}

body.dark-mode .seasons button {
  /* background-color: var(--background-color-dark); */
  color: var(--text-color-dark);
}

.seasons button,
.seasons h3 {
  display: inline-block;
  font-family: "Roboto Mono", sans-serif !important;
  align-items: center;
  color: var(--text-color-light);
}

.seasons h3 {
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.seasons button {
  border: none;
  border-bottom: 2px solid transparent;

  /* margin-right: 20px; */

  padding-left: 20px;
  padding-right: 20px;

  padding-top: 25px;
  padding-bottom: 15px;
  background-color: transparent;
  transition: gray 0.3s ease;
}

.seasons button:hover {
  border-color: #969696;
}

.seasons button.active {
  border-color: #788a92; /* Change this to the color you want for the active button */
}

.diary-label {
  margin: 10px 0px 10px 19%;
}
.diary-label h5 {
  display: inline-block;
  border-radius: 8px;
  background: #d3d3d3;
  padding: 5px 10px;
}
body.dark-mode .diary-label {
  color: var(--text-color-light);
}

.conversation-character {
  width: auto;
  margin-right: 10px;
  line-height: 1.5;
}
.conversation-text {
  margin-right: 15px;
  line-height: 1.5;
}
.blog-page-images {
  padding-top: 15px;
  text-align: center;
  padding-bottom: 15px;
}
.blog-page-image-grid {
  border-left: 4px solid #969696;
  margin-left: 20%;
  padding-right: 22%;
  margin-bottom: 30px;
  grid-template-columns: auto auto auto auto auto auto auto;
  justify-content: left;
  display: grid;
}
.blog-page-image-grid img {
  margin-right: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.blog-page-images-theImage {
  padding-left: auto;
  padding-right: auto;
}
.blog-giant-carousel1 {
  background: transparent;
  height: 440px;
  width: 500px;
  background: transparent;
  border: 3px rosybrown;
  display: center;
  align-items: center;
  margin-bottom: 20px;

  .react-multiple-carousel__arrow--left {
    left: calc(-3%);
    bottom: calc(-2%);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(-3%);
    bottom: calc(-2%);
  }
}
.blog-giant-carousel2 {
  height: 575px;
  width: 500px;
  margin-top: 0px;
  margin-bottom: 0px;
  border: 3px rosybrown;
  display: center;
  align-items: center;
  .react-multiple-carousel__arrow--left {
    left: calc(-3%);
    bottom: calc(-1%);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(-3%);
    bottom: calc(-1%);
  }
}
.blog-giant-carousel3 {
  height: 450px;
  width: 500px;
  margin-top: 0px;
  border: 3px rosybrown;
  display: center;
  align-items: center;

  .react-multiple-carousel__arrow--left {
    left: calc(-3%);
    bottom: calc(-2%);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(-3%);
    bottom: calc(-2%);
  }
}

.blog-carousel-component {
  border-radius: 18px;
}
.blog-carousel-images {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.blog-carousel-images img {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 600px) {
  .blog-carousel-images img {
    max-width: 100% !important;
  }
}
/* .blog-carousel-lines{
  width:auto;
}  */
/* .blog-page ul li{
  display: flex;
  text-align:right
} */
.blog-page ul li time p {
  position: absolute;
  right: 25%;
}
.blog-page-the-end {
  margin-top: 15px;
  text-align: center;
}

.dropdown-toggle {
  position: relative;
  display: flex;
  align-items: center;
  color: #788a92;
  /* background: #2b2b2c; */
  padding-right: 50px;
  margin-left: 18%;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  border: 2px solid #2b2b2c;
  /* border-left: solid black; */

  font-family: "Roboto mono";
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.dropdown-toggle p {
  margin-left: 8px;
}
.dropdown-toggle-notes {
  color: #969696;
  background: none;
  margin-left: 15px !important;
  font-size: 12px;
  width: 80px !important;
  height: 25px;

  position: relative;
  display: flex;
  /* color: #788a92; */
  /* background: #2b2b2c; */
  padding-right: 50px;
  border: none;
  border-bottom: 2px solid #969696;

  font-family: "Roboto mono";
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.button-toggled-notes {
  border-bottom: 2px solid #788a92;
}
.dropdown-toggle-notes:hover {
  color: #788a92;
}

.dark-mode .dropdown-toggle {
  background-color: #2b2b2c;
}

.dark-mode .dropdown-toggle:hover {
  filter: brightness(0.8);
}

body.dark-mode .dropdown-toggle-notes {
  /* background-color: var(--background-color-dark); */
  color: var(--text-color-dark);
}

.dropdown-toggle:hover {
  background: #2b2b2c;
  cursor: pointer;
}
body.dark-mode .dropdown-toggle {
  border: 2px solid gray;
}

.button-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  overflow: hidden;
  transition: 0.5s ease-in-out; /* Using transition for a smoother effect */
  height: 0px;
  font-size: 11px;
}

.dropdown-menu.open {
  /* background: #2b2b2c;
  color: #F0F0F5; */
  display: block;
  height: auto; /* Allow the content to dictate the height when open */
}

.dropdown-menu.open li {
  margin-top: -7px;
  margin-bottom: 10px;
  list-style: circle !important;
}

/* light mode dark mode */
.light-mode {
  background-color: red;
  color: cyan;
}
.dark-mode {
  background-color: magenta;
  color: yellow;
}

.spotify-music {
  border-radius: 12px;
  width: 420px;
  height: 80px;
}

/* ProjectDM */
body.dark-mode .blog-page-images-ProjectDM {
  filter: invert(100%);
}

.quotes-ProjectDM {
  margin-left: 12px;
  padding-top: -10px;
  padding-bottom: -10px;
  padding-left: 10px;
  font-size: 10px;
  border-left: 2.5px solid #969696;
  /* padding-left: 13px; */
  /* text-indent: -7px; */
}

@media (max-width: 600px) {
  .upvote-and-title {
    margin-left: 5% !important;
    margin-right: 20%;
    justify-content: left;
  }
}

.upvote-and-title {
  display: grid;
  grid-template-columns: auto auto auto !important;
  margin-left: 20%;
  margin-right: 5%;
  margin-bottom: 25px;
  justify-content: left;
  align-items: center;
}
.upvote-and-title h2,
.upvote-and-title date {
  margin-left: 18px;
}

.upvote-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sqlTest:hover,
.sqlTestUpvoted:hover {
  cursor: pointer;
}
.upvoteCount {
  font-size: 10px;
}
.sqlTestUpvoted {
  color: var(--text-color-blue);
}
