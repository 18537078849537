body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    display: block;
    margin: 0;
    font-size: 12px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    padding-bottom: 50px;

    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    font-size: 10px;
}



.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

body.dark-mode .modal-content{
    /* background-color: var(--background-color-dark); */
    background: var(--text-color-light);
  }

  
  .center-border{
    border-bottom: 3px solid var(--text-color-blue) ;
    margin: 15px 25%;

  }

  .book-description{
    font-size:8px;
  }